<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <v-row><v-col cols="4">
                  <div class="card-header-padding">
                    <div class="d-flex align-center">
                      <div>
                        <h5 class="font-weight-bold text-h5 text-typo mb-0">
                          Purchase report
                        </h5>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col offset-md="6">
                  <v-btn v-if="!searchByDate" @click="searchByDates()" elevation="0" :ripple="false" height="43" dark
                    class="
                                                                                                font-weight-bold
                                                                                                text-capitalize
                                                                                                btn-ls btn-primary
                                                                                                bg-success
                                                                                                px-6
                                                                                              ">Search By Dates</v-btn>
                  <v-btn v-if="searchByDate" @click="searchBysaleOffer()" elevation="0" :ripple="false" height="43" dark
                    class="
                                                                                                font-weight-bold
                                                                                                text-capitalize
                                                                                                btn-ls btn-primary
                                                                                                bg-success
                                                                                                px-6
                                                                                              ">Search By
                    Offer/Sale</v-btn>
                </v-col>
              </v-row>

              <v-card-actions> </v-card-actions>
              <v-card-text>
                <v-form ref="frm">
                  <v-card-text class="px-0 py-0">
                    <!-- Date Wise -->
                    <v-row v-if="searchByDate">
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>Buying type</label>

                        <v-autocomplete :items="buyingType" item-text="name" item-value="name" hide-details="auto"
                          v-model="editedItem.buyingType" @blur="getVendors" multiple class="
                                                                                                      input-style
                                                                                                      font-size-input
                                                                                                      text-light-input
                                                                                                      placeholder-light
                                                                                                      border-radius-md
                                                                                                      select-style
                                                                                                      mt-5
                                                                                                      mb-0
                                                                                                    " outlined
                          height="46" single-line :rules="[(v) => !!v || 'Buying type is required']"
                          placeholder="Select Buying type">
                          <template v-slot:selection="{ item }">
                            <v-chip label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>From (Start date)</label>

                        <v-menu ref="start_date_menu" v-model="start_date_menu" :close-on-content-click="false"
                          :return-value.sync="start_date_menu" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="editedItem.start_date" v-bind="attrs" v-on="on" hide-details="auto"
                              class="
                                                                                                          input-style
                                                                                                          font-size-input
                                                                                                          text-light-input
                                                                                                          placeholder-light
                                                                                                          input-icon
                                                                                                          mt-5
                                                                                                        " dense flat
                              filled solo height="46" placeholder="Start Date" persistent-hint></v-text-field>
                          </template>
                          <v-date-picker color="green lighten-1" header-color="primary" v-model="editedItem.start_date"
                            :max="editedItem.end_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="start_date_menu = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="
                              $refs.start_date_menu.save(
                                editedItem.start_date
                              )
                            ">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>To
                          (End date)</label>

                        <v-menu ref="end_date_menu" v-model="end_date_menu" :close-on-content-click="false"
                          :return-value.sync="end_date_menu" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="editedItem.end_date" v-bind="attrs" v-on="on" hide-details="auto"
                              class="
                                                                                                          input-style
                                                                                                          font-size-input
                                                                                                          text-light-input
                                                                                                          placeholder-light
                                                                                                          input-icon
                                                                                                          mt-5
                                                                                                        " dense flat
                              filled solo height="46" placeholder="To Date" persistent-hint></v-text-field>
                          </template>
                          <v-date-picker color="green lighten-1" header-color="primary" v-model="editedItem.end_date"
                            :min="editedItem.start_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="end_date_menu = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="
                              $refs.end_date_menu.save(editedItem.end_date)
                            ">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label class="text-md text-typo font-weight-bolder ms-1">Vendors</label>
                        <v-autocomplete v-model="vendor" :items="vendors" item-text="name" item-value="id" multiple
                          color="rgba(0,0,0,.6)" class="
                                                                                                      input-style
                                                                                                      font-size-input
                                                                                                      text-light-input
                                                                                                      placeholder-light
                                                                                                      border-radius-md
                                                                                                      select-style
                                                                                                      mb-0
                                                                                                      mt-5
                                                                                                    "
                          placeholder="Select a Vendor" outlined single-line height="48"><template
                            v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ vendor.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleVendor">
                              <v-list-item-action>
                                <v-icon :color="
                                  vendor.length > 0 ? 'indigo darken-4' : ''
                                ">
                                  {{ iconvendor }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <!-- Season Wise -->
                    <v-row dense v-if="!searchByDate">
                      <v-col cols="12" md="3">
                        <label class="text-md text-typo font-weight-bolder ms-1">Season</label>

                        <v-autocomplete :items="seasons" item-value="id" hide-details="auto" v-model="season" class="
                                                                                                      input-style
                                                                                                      font-size-input
                                                                                                      text-light-input
                                                                                                      placeholder-light
                                                                                                      border-radius-md
                                                                                                      select-style
                                                                                                      mb-0
                                                                                                    " outlined
                          height="46" @change="filterSeasonSale()" single-line
                          :rules="[(v) => !!v || 'Season is required']" placeholder="Select Season">
                          <template v-slot:selection="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="3">
                        <label class="text-md text-typo font-weight-bolder ms-1">Auction center</label>

                        <v-autocomplete :items="auctionCenters" item-text="auction_center_name" item-value="id"
                          hide-details="auto" v-model="auctionCenter" multiple class="
                                                                                                      input-style
                                                                                                      font-size-input
                                                                                                      text-light-input
                                                                                                      placeholder-light
                                                                                                      border-radius-md
                                                                                                      select-style
                                                                                                      mb-0
                                                                                                    " outlined
                          height="46" single-line :rules="[(v) => !!v || 'Auction center is required']"
                          placeholder="Select auction center">
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ auctionCenter.length - 1 }}
                              others)
                            </span> </template><template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleAuctionCenter">
                              <v-list-item-action>
                                <v-icon :color="
                                  auctionCenter.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                ">
                                  {{ iconAuctionCenter }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <label class="text-md font-weight-bolder ms-1 mandatory">Vendor</label>
                        <v-autocomplete v-model="vendor" :items="vendors" item-text="name" item-value="id" multiple
                          color="rgba(0,0,0,.6)" class="
                                                                                                      input-style
                                                                                                      font-size-input
                                                                                                      text-light-input
                                                                                                      placeholder-light
                                                                                                      border-radius-md
                                                                                                      select-style
                                                                                                      mb-0
                                                                                                    "
                          placeholder="Select a Vendor" :rules="[(v) => !!v || 'Vendor is Required']" outlined single-line
                          height="48"><template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ vendor.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleVendor">
                              <v-list-item-action>
                                <v-icon :color="
                                  vendor.length > 0 ? 'indigo darken-4' : ''
                                ">
                                  {{ iconvendor }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-btn @click="searchData()" elevation="0" :ripple="false" height="43" dark class="
                                                                                                      font-weight-bold
                                                                                                      text-capitalize
                                                                                                      btn-ls btn-primary
                                                                                                      bg-success
                                                                                                      px-6
                                                                                                      mt-7
                                                                                                    ">Load
                          Offer/Sale</v-btn>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="!searchByDate">
                      <v-col cols="3"></v-col>
                      <v-col cols="12" md="3" v-show="showOfferSale">
                        <label class="text-md text-typo font-weight-bolder ms-1">Season sale</label>

                        <v-autocomplete :items="seasonSales" item-value="id" item-text="season_sale" hide-details="auto"
                          v-model="seasonSale" color="rgba(0,0,0,.6)" class="
                                                                                                      input-style
                                                                                                      font-size-input
                                                                                                      text-light-input
                                                                                                      placeholder-light
                                                                                                      border-radius-md
                                                                                                      select-style
                                                                                                      mb-0
                                                                                                    " outlined chips
                          multiple height="46" single-line :rules="[
                            (v) => v.length > 0 || 'Sale No is required',
                          ]" placeholder="Select Sale No">
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">
                                {{ item.auction_center_name }}-{{
                                  item.season_sale
                                }}
                              </span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ seasonSale.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleSalesNo">
                              <v-list-item-action>
                                <v-icon :color="
                                  seasonSales.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                ">
                                  {{ iconSale }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" v-show="showOfferSale">
                        <label class="text-md font-weight-bolder ms-1 mandatory">Offer No</label>
                        <v-autocomplete multiple v-model="offer" :items="offers" item-text="offer_no" item-value="id"
                          color="rgba(0,0,0,.6)" class="
                                                                                                      input-style
                                                                                                      font-size-input
                                                                                                      text-light-input
                                                                                                      placeholder-light
                                                                                                      border-radius-md
                                                                                                      select-style
                                                                                                      mb-0
                                                                                                    "
                          placeholder="Select a Vendor" :rules="[(v) => !!v || 'Offer is Required']" outlined single-line
                          height="48">
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{ item.name }}-{{ item.offer_no }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ offer.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleOffer">
                              <v-list-item-action>
                                <v-icon :color="
                                  offer.length > 0 ? 'indigo darken-4' : ''
                                ">
                                  {{ iconoffer }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row dense></v-row>
                  </v-card-text>

                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <v-btn @click="close" elevation="0" :ripple="false" height="43" class="
                                                                                                  font-weight-bold
                                                                                                  text-capitalize
                                                                                                  btn-ls btn-secondary
                                                                                                  bg-light
                                                                                                  px-6
                                                                                                ">Clear</v-btn>

                    <v-btn @click="searchPurchase()" elevation="0" :ripple="false" height="43" dark class="
                                                                                                  font-weight-bold
                                                                                                  text-capitalize
                                                                                                  btn-ls btn-primary
                                                                                                  bg-success
                                                                                                  px-6
                                                                                                ">Search</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card-text>
              <v-card-text class="px-0 py-0" v-if="items.length > 0">
                <v-data-table :headers="headers" :items="items" item-key="id" class="table" :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }" mobile-breakpoint="0">
                  <!-- dateFormater -->
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Purchase history</v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-btn @click="excelExport" elevation="0" :ripple="false" height="43" dark class="
                                                                                                    font-weight-bold
                                                                                                    text-capitalize
                                                                                                    btn-primary
                                                                                                    bg-success
                                                                                                    py-5
                                                                                                    px-5
                                                                                                    shadow
                                                                                                  ">
                        <v-icon size="20" class="text-white mr-1">
                          fas fa-cloud-download-alt
                        </v-icon>
                        Download
                      </v-btn>
                    </v-toolbar> </template><template v-slot:[`item.auction_date`]="{ item }">
                    <span>
                      {{ formatAuctionDate(item.auction_date) }}
                    </span>
                  </template>
                  <template v-slot:[`item.gp_date`]="{ item }">
                    <span>
                      {{ formatDate(item.gp_date) }}
                    </span>
                  </template>
                  <template v-slot:[`item.sale_date`]="{ item }">
                    <span>
                      {{ formatDate(item.sale_date) }}
                    </span>
                  </template>
                  <template v-slot:[`item.sl_no`]="{ item }">
                    <span>
                      {{ findSerialNo(item) }}
                    </span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  name: "PurchaseReport",
  data() {
    return {
      page: 1,
      pageCount: 0,
      overlay: false,
      buyingType: [
        { name: "AUCTION" },
        { name: "PRIVATE" },
        // { name: "INTERNAL TRANSFER" },
      ],

      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",
      editedIndex: -1,
      start_date_menu: false,
      end_date_menu: false,
      editedItem: {
        buyingType: "",
        start_date: null,
        end_date: null,
      },
      defaultItem: {
        buyingType: "",
        start_date: null,
        end_date: null,
      },
      headers: [
        {
          text: "#",
          width: "80",
          value: "sl_no",
        },
        {
          text: "Type",
          // width: "100",
          value: "purchase_type",
        },
        {
          text: "Vendor",
          width: "150",
          value: "vendor_name",
        },
        {
          text: "Warehouse",
          width: "150",
          value: "warehouse_name",
        },
        {
          text: "Auction Centre",
          width: "100",
          value: "auction_center",
        },
        {
          text: "Season",
          width: "150",
          value: "season_name",
        },
        {
          text: "Sale/Offer",
          width: "150",
          value: "sale",
        },
        {
          text: "PO Number",
          width: "150",
          value: "po_number",
        },
        {
          text: "Sale Date",
          width: "150",
          value: "sale_date",
        },
        {
          text: "Auction Date",
          width: "150",
          value: "auction_date",
        },
        {
          text: "Lot No",
          width: "150",
          value: "lot_no",
        },
        {
          text: "Tea Type",
          width: "150",
          value: "tea_type",
        },
        {
          text: "Mark",
          width: "150",
          value: "mark_name",
        },
        {
          text: "Grade",
          width: "150",
          value: "grade_name",
        },
        {
          text: "Invoice No.",
          width: "150",
          value: "invoice_number",
        },
        {
          text: "No. of Packs",
          width: "100",
          value: "no_packages",
        },
        {
          text: "Invoice Weight",
          width: "100",
          value: "invoice_weight",
        },
        {
          text: "Lot Price",
          width: "100",
          value: "lot_price",
        },
        {
          text: "Total Cost",
          width: "100",
          value: "total_cost",
        },
        {
          text: "Item Code",
          width: "150",
          value: "item_name",
        },
        {
          text: "GP No.",
          width: "100",
          value: "gp_no",
        },
        {
          text: "GP Date",
          width: "150",
          value: "gp_date",
        },
      ],
      auctionCenters: [],
      auctionCenter: [],
      seasons: [],
      season: null,
      seasonSales: [],
      seasonSale: [],
      vendors: [],
      vendor: [],
      vendor_seasons: [],
      vendor_season: null,
      offers: [],
      offer: [],
      showOfferSale: false,
      searchByDate: false,
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.editedItem.start_date = this.formatDate(d);
      this.editedItem.end_date = this.formatDate(d1);
    },
    async getVendors() {
      if (this.editedItem.buyingType.length > 0) {
        this.overlay = true;
        this.vendors = await api.getVendors(this.editedItem.buyingType);
        this.overlay = false;
      }
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    formatAuctionDate(date) {
      return date.substr(0, 10);
    },
    validate() {
      return this.$refs.frm.validate();
    },
    findSerialNo(item) {
      return this.items.indexOf(item) + 1;
    },
    excelExport() {
      let data = this.items.map((item, i) => {
        return {
          "#": i + 1,
          "Type": item.purchase_type,
          "Vendor": item.vendor_name,
          "Warehouse": item.warehouse_name,
          "Auction Centre": item.auction_center,
          "Season": item.season_name,
          "Sale/Offer": item.sale,
          "PO Number": item.po_number,
          "Sale Date": this.formatDate(item.sale_date),
          "Auction Date": this.formatAuctionDate(item.auction_date),
          "Lot No": item.lot_no,
          "Tea Type": item.tea_type,
          "Mark": item.mark_name,
          "Grade": item.grade_name,
          "Invoice No.": item.invoice_number,
          "No. of packs": item.no_packages,
          "Net": item.net_weight,
          "Invoice Weight": item.invoice_weight,
          "Lot Price": item.lot_price,
          "Total Cost": item.total_cost,
          "Item Code": item.item_name,
          "Period Of Manufacture": item.period_of_manufacture,
          "GP No.": item.gp_no,
          "GP Date": this.formatDate(item.gp_date),
          "Company Warehouse": item.company_warehouse,
        };
      });

      let date_time = new Date();
      date_time = `${(date_time.getMonth() + 1).toString().padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time
        .getFullYear()
        .toString()}`;
      let fileName = `PurchaseReport-` + date_time.replaceAll(`/`, `-`);

      try {
        json2excel({
          data,
          name: fileName,
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("Excel export error:", e);
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,

        showConfirmButton: false,
      });
    },

    async initialize() {
      try {
        this.seasons = await apiMaster.getSeason();

        // this.setDates();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    closeDialogue() {
      this.dialog = false;
      this.$refs.frmSave.reset();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
      });
    },
    reset() {
      this.$refs.frm.reset();
    },
    async filterSeasonSale() {
      this.overlay = true;
      this.auctionCenters = await api.getAuctionCenterBySeason(this.season);
      this.vendors = await api.getVendorBySeason(this.season);
      this.overlay = false;
    },
    searchByDates() {
      this.vendors = [];
      this.vendor = [];
      this.searchByDate = true;
    },
    searchBysaleOffer() {
      this.vendors = [];
      this.vendor = [];
      this.searchByDate = false;
    },
    async searchData() {
      // console.log("TH", this.auctionCenter, this.vendor);
      this.overlay = true;

      this.seasonSales = await api.getSeasonSalesByAcutionCenters({
        auction_centers: this.auctionCenter,
        season: this.season,
      });
      console.log("this.seasonSales", this.auctionCenter);
      this.offers = await api.getOfferByVendor({
        vendors: this.vendor,
        season: this.season,
      });
      this.showOfferSale = true;
      this.overlay = false;
    },
    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    saveValidation() {
      return this.$refs.frmSave.validate();
    },
    dateFormater(date) {
      return formatDate.formatDate(date);
    },
    async getSeason() {
      try {
        this.vendor_seasons = [];
        this.vendor_season = null;
        this.overlay = true;
        this.vendor_seasons = await apiMaster.getSeasonByVendor(this.vendor.id);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    async getOffers() {
      try {
        this.offers = [];
        this.offer = null;
        this.overlay = true;
        this.offers = await apiMaster.getOffersByvendorSeason(
          this.vendor.id,
          this.vendor_season.id
        );

        console.log("this.offers", this.offers);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    async searchPurchase() {
      // if (this.validate()) {
      this.items = [];
      this.overlay = true;
      try {
        console.log("SALE", this.seasonSale);
        console.log("OFFER", this.offer);
        let slug = null;
        if (this.searchByDate == true) {
          slug = {
            purchase_type: this.editedItem.buyingType,
            vendor: this.vendor,
            fromDate: this.editedItem.start_date,
            toDate: this.editedItem.end_date,
          };
        } else {
          let ptype = [];
          if (this.seasonSale.length > 0) {
            ptype.push("AUCTION");
          }
          if (this.offer.length > 0) {
            ptype.push("PRIVATE");
          }
          slug = {
            sale: this.seasonSale,
            offer_no: this.offer,
            purchase_type: ptype,
          };
        }

        console.log("SLUG", slug);
        this.items = await api.getPurchaseReportMain(slug);
        console.log("this.items", this.items);
        if (this.items.length == 0) {
          this.noDataAlert("No Purchase Data Found");
        }
      } catch (err) {
        this.showErrorAlert(err.message);
      } finally {
        this.overlay = false;
      }
      // }
    },
    toggleSalesNo() {
      this.$nextTick(() => {
        if (this.selectAllSale) {
          this.seasonSale = [];
        } else {
          this.seasonSale = this.seasonSales.slice().map((item) => {
            return item.id;
          });
        }
      });
    },
    toggleOffer() {
      this.$nextTick(() => {
        if (this.selectAlloffer) {
          this.offer = [];
        } else {
          this.offer = this.offers.slice().map((item) => {
            return item.id;
          });
        }
      });
    },
    toggleVendor() {
      this.$nextTick(() => {
        if (this.selectAllVendor) {
          this.vendor = [];
        } else {
          this.vendors.forEach((element) => {
            this.vendor.push(element.id);
          });
        }
      });
    },
    toggleAuctionCenter() {
      this.$nextTick(() => {
        if (this.selectAllAuctionCenter) {
          this.auctionCenter = [];
        } else {
          this.auctionCenters.forEach((element) => {
            this.auctionCenter.push(element.id);
          });
        }
      });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Allocation" : "Edit Allocation";
    },
    selectAllSale() {
      return this.seasonSale.length == this.seasonSales.length;
    },
    selectSomeSale() {
      return this.seasonSale.length > 0 && !this.selectAllSale;
    },

    iconSale() {
      if (this.selectAllSale) return "ni-fat-remove text-lg";
      if (this.selectSomeSale) return "ni-fat-delete";
      return "fa-square-o";
    },
    //
    selectAlloffer() {
      return this.offer.length == this.offers.length;
    },
    selectSomeoffer() {
      return this.offer.length > 0 && !this.selectAlloffer;
    },

    iconoffer() {
      if (this.selectAlloffer) return "ni-fat-remove text-lg";
      if (this.selectSomeoffer) return "ni-fat-delete";
      return "fa-square-o";
    }, //
    selectAllVendor() {
      return this.vendor.length == this.vendors.length;
    },
    selectSomeVendor() {
      return this.vendor.length > 0 && !this.selectAllVendor;
    },

    iconvendor() {
      if (this.selectAllVendor) return "ni-fat-remove text-lg";
      if (this.selectSomeVendor) return "ni-fat-delete";
      return "fa-square-o";
    }, //
    selectAllAuctionCenter() {
      return this.auctionCenter.length == this.auctionCenters.length;
    },
    selectSomeAuctionCenter() {
      return this.auctionCenter.length > 0 && !this.selectAllAuctionCenter;
    },

    iconAuctionCenter() {
      if (this.selectAllAuctionCenter) return "ni-fat-remove text-lg";
      if (this.selectSomeAuctionCenter) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
